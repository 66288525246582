<template>
  <v-list-item
    class="mb-0 justify-space-between pl-3 flex flex-column"
    active-class="white--text"
    to="/"
    :ripple="false"
  >
      <v-img
        :src="logoImage"
        class="mt-4"
      />

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        Exile Tracker
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  // Utilities
  import { sync } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawerHeader',

    computed: {
      logoImage () {
        return '/img/ExileTracker.png'
      },

      ...sync('app', [
        'mini',
      ]),
    },
  }
</script>
